
<template>
  <router-link to="/">
    <img
      v-if="isNotKCHFUser"
      :src="dot.src"
      class="dot-logo"
      :alt="dot.alt"
      loading="lazy"
    />
    <template v-else>
      <img
        v-for="(image, i) in images" :key="i"
        :src="require(`@/assets/images/external/kchf/${image.src}`)"
        class="kchf-logo"
        :alt="image.alt"
        loading="lazy"
      />
      &nbsp;
    </template>
  </router-link>
</template>

<script setup>
import { reactive, ref } from 'vue';
import global from "global";

const isNotKCHFUser = ref(!global.isKCHFUser());

const dot = reactive({
  src: '/theme/responsive_dot/include/img/dot_logo.svg',
  alt: 'Dot Incorporation Logo',
});
const images = reactive([
  {
    // 한국 문화재청 로고
    src: 'cha.go.kr.png',
    alt: '한국 문화재청 로고',
  },
  {
    // 한국 문화재단 로고
    src: 'chf.or.kr.png',
    alt: '한국 문화재단 로고',
  },
]);
</script>

<style scoped>
.dot-logo {
  width: 100px;
  height: 25px;
}
.kchf-logo {
  width: 100px;
  height: 56px;
}
</style>