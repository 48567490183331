/*
 * =====================================================================
 * 작 업 명  :
 * 파 일 명  : drive.js
 * 변경이력
 * 2023-01-05 / 이성준 / 최초작성
 * =====================================================================
 */
import {$axios, $session} from "dot-framework";
import DTMSCanvas from "@/js/DTMSCanvas";

// card형 목록의 DTMS 썸네일 생성을 위한 캔버스 배열
const canvas = [];
const canvasWidth = 99;
const canvasHeight = 99;
/**
 * @type {Readonly<{CUT: symbol, COPY: symbol}>}
 */
const CLIPBOARD_MODE = Object.freeze({
    CUT: Symbol("CUT"),
    COPY: Symbol("COPY"),
});
/**
 * @type {Readonly<{CARD: symbol, LIST: symbol}>}
 */
const VIEW_MODE = Object.freeze({
    CARD: Symbol("CARD"),
    LIST: Symbol("LIST"),
});

const state = () => ({
    clipboard: [], // 선택한 행 복사(클립보드)
    clipboardMode: null,
    currentGroup: "",
    driverKind: "P", // Private Drive
    items: [],
    redoStack: [],
    selectedItems: [],
    undoStack: [],
    viewMode: VIEW_MODE.CARD,
});

// getters
const getters = {
    clipboard: (state) => {
        return state.clipboard;
    },
    clipboardMode: (state) => {
        return state.clipboardMode;
    },
    driverKind: (state) => {
        return state.driverKind;
    },
    filteredItems: (state) => (textFilter) => {
        //FIXME: metacharacter(?:^|\\W)
        textFilter = textFilter.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(textFilter, 'i');

        return state.items.filter(item => regex.test(item.FILE_NAME));
    },
    getCurrentGroupNo: (state) => {
        return state.currentGroup;
    },
    isPrivate: (state) => {
        return state.driverKind === "P";
    },
    isPublic: (state) => {
        return state.driverKind === "D";
    },
    items: (state) => {
        return state.items;
    },
    selectedItems: (state) => {
        return state.selectedItems;
    },
    viewMode: (state) => {
        return state.viewMode;
    }
};

const findIndexOfSelectedItem = (state, item) => {
    return state.selectedItems.findIndex(x => x.FILE_KEY === item.FILE_KEY);
};

// mutations
const mutations = {
    clearClipboard(state) {
        state.clipboard = [];
    },
    clearSelectedItems(state) {
        state.selectedItems = [];
    },
    pushToClipboard(state, clipboard) {
        state.clipboard.push(...clipboard);
    },
    pushToSelectedItems(state, data) {
        const items = Array.isArray(data) ? data : [data];

        for (const item of items) {
            const indexToUpdate = findIndexOfSelectedItem(state, item);

            if (indexToUpdate !== -1) {
                state.selectedItems[indexToUpdate] = item;
            } else {
                state.selectedItems.push(item);
            }
        }
    },
    removeSelectedItem(state, item) {
        const indexToRemove = findIndexOfSelectedItem(state, item);

        if (indexToRemove !== -1) {
            state.selectedItems.splice(indexToRemove, 1);
        }
    },
    redo(state) {
        if (state.redoStack.length === 0) return;
        const command = state.redoStack.pop();
        command.redo();
        state.undoStack.push(command);
    },
    setClipboardMode(state, mode) {
        state.clipboardMode = CLIPBOARD_MODE[mode];
    },
    setCurrentGroup(state, groupNo) {
        state.groupNo = groupNo;
    },
    setDriveKindStatus(state, status) {
        state.driverKind = status;
    },
    setItems(state, items) {
        state.items = items;
    },
    setViewMode(state, status) {
        state.viewMode = VIEW_MODE[status];
    },
    undo(state) {
        if (state.undoStack.length === 0) return;
        const command = state.undoStack.pop();
        command.undo();
        state.redoStack.push(command);
    },
    pushUndoStack(state, command) {
        state.undoStack.push(command);
        // 새 커맨드를 실행할 때마다 redo 스택 초기화
        state.redoStack = [];
    }
};

// actions
const actions = {
    /**
     * card형 목록의 DTMS 썸네일 생성
     * @param context
     */
    createDTMSCanvasOfCardTypeItems(context) {
        for (let i = 0; i < context.state.items.length; i++) {
            const obj = context.state.items[i];
            if (obj.FILE_TYPE === 'FILE') {
                const DTMSJson = obj.DTMS_JSON;

                if (DTMSJson) {
                    const pixelArr = DTMSCanvas.getPixelArray(DTMSJson.items[0]);
                    const params = {
                        pixelArr: pixelArr,
                        canvasId: `canvas-${obj.FILE_KEY}-0`,
                        canvasWidth,
                        canvasHeight,
                    };

                    canvas[i] !== undefined && canvas[i].dispose();
                    const staticCanvas = DTMSCanvas.createStaticCanvas(params.canvasId, params.canvasWidth, params.canvasHeight);
                    staticCanvas.add(DTMSCanvas.drawTactileDataOnTheCanvas(params.pixelArr, params.canvasWidth, params.canvasHeight));
                    canvas[i] = staticCanvas;
                }
            }
        }

        canvas.splice(context.state.items.length);
    },
    executeCommand(context, command) {
        command.execute();
        context.commit("pushUndoStack", command);
    },
    /**
     * @param context
     * @param payload
     * @param {string} payload.parentGroupNo
     * @param rootState
     * @param rootGetters
     * @returns {Promise<*>}
     */
    async fetchDtmsGroupList(context, payload, rootState, rootGetters) {
        const parentGroupNo = payload?.parentGroupNo || (new URLSearchParams(window.location.search).get('parentGroupNo') || "");
        const params = {
            COMP_NO: $session.getCompNo() || rootGetters.COMP_NO,
            PARENT_GROUP_NO: parentGroupNo,
            DRIVER_KIND: context.state.driverKind,
            USER_NO: $session.getUserNo() !== "GUEST" ? $session.getUserNo() : "",
            APP_TYPE: "A",
        };
        // 값이 없는 속성 제거
        Object.keys(params)
          .map(key => {
              if (!params[key]) {
                  delete params[key];
              }
          });
        context.commit("setCurrentGroup", payload?.parentGroupNo || "ROOT")
        const query = Object.keys(params).length !== 0 ? `?${new URLSearchParams(params).toString()}` : "";
        const url = `/drive-app/v1/dtms/groups${query}`;
        const promise = $axios.get(url);
        const response = await promise;

        if (response.status === 401) {
            setTimeout(async () => await context.dispatch("reloadItems", payload), 1000);
        }

        context.commit("setItems", response.data.items);

        return promise;
    },
    redo(context) {
        context.commit("redo");
    },
    async reloadItems(context, payload) {
        await context.dispatch("fetchDtmsGroupList", payload);

        switch (context.state.viewMode) {
            case VIEW_MODE.CARD:
                await context.dispatch("createDTMSCanvasOfCardTypeItems");
                break;
        }
    },
    undo(context) {
        context.commit("undo");
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
