<template>
  <ul class="bg-dot-gray dropdown-menu dropdown-menu-lg-end" aria-labelledby="braille-language-dropdown-button">
    <li v-for="(languageOption, index) in brailleLanguageOptions" :key="index" :aria-selected="selectedLanguage === languageOption">
      <a href="#" class="dropdown-item" role="button" @click="selectLanguage(languageOption)">
        <svg v-show="selectedLanguage === languageOption" style="margin-right: 5px;" width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.61189 0.972961C8.83016 1.20725 8.82897 1.57076 8.60919 1.80362L3.23265 7.5L0.38531 4.4436C0.168237 4.21059 0.168238 3.84942 0.385311 3.6164V3.6164C0.625056 3.35906 1.03268 3.35881 1.27274 3.61586L3.23265 5.71453L7.72676 0.969321C7.96763 0.715 8.37313 0.716668 8.61189 0.972961V0.972961Z" fill="white"/>
        </svg>
        {{ languageOption.text }}
      </a>
    </li>
  </ul>
</template>

<script setup>
import {onBeforeMount, onMounted, ref} from "vue";
import {$axios, $session} from "dot-framework";
import store from "@/store/index.js";
import isEmpty from "lodash-es/isEmpty";

let id = 0;

const selectedLanguage = ref({});
const brailleLanguageOptions = ref([
  // {id: id++, kind: "",  language: "", grade: "", pin: 0, text: "점자언어를 선택하세요", default: false},
  {id: id++, kind: "DOT", language: "arabic", grade: "1", pin: 6, text: "العربية - Grade1", default: true},
  {id: id++, kind: "DOT", language: "arabic", grade: "2", pin: 6, text: "العربية - Grade2", default: false},
  {id: id++, kind: "DOT", language: "simplified chinese", grade: "1", pin: 6, text: "简体中文 - Xianxing(Shengdiao)", default: true},
  {id: id++, kind: "DOT", language: "simplified chinese", grade: "2", pin: 6, text: "繁體中文 - Xianxing(No Shengdiao)", default: false},
  {id: id++, kind: "DOT", language: "simplified chinese", grade: "3", pin: 6, text: "简体中文 - Shuangpin", default: false},
  {id: id++, kind: "LIBLOUIS", language: "catalan", grade: "1", pin: 6, text: "català - Grade1", default: true},
  {id: id++, kind: "LIBLOUIS", language: "czech", grade: "1", pin: 6, text: "čeština - Grade1", default: true},
  {id: id++, kind: "LIBLOUIS", language: "danish", grade: "1", pin: 6, text: "Dansk - Grade1", default: true},
  {id: id++, kind: "LIBLOUIS", language: "danish", grade: "2", pin: 6, text: "Dansk - Grade2", default: false},
  {id: id++, kind: "DOT", language: "english", grade: "1", pin: 6, text: "English - Grade1", default: false},
  {id: id++, kind: "DOT", language: "english", grade: "2", pin: 6, text: "English - Grade2", default: true},
  {id: id++, kind: "LIBLOUIS", language: "finnish", grade: "1", pin: 6, text: "Suomen - Grade1", default: true},
  {id: id++, kind: "LIBLOUIS", language: "finnish", grade: "2", pin: 6, text: "Suomen - Grade2", default: false},
  {id: id++, kind: "LIBLOUIS", language: "french", grade: "1", pin: 6, text: "Français - Grade1", default: true},
  {id: id++, kind: "LIBLOUIS", language: "french", grade: "2", pin: 6, text: "Français - Grade2", default: false},
  {id: id++, kind: "LIBLOUIS", language: "german", grade: "1", pin: 6, text: "Deutsch - Grade1", default: false},
  {id: id++, kind: "LIBLOUIS", language: "german", grade: "2", pin: 6, text: "Deutsch - Grade2", default: true},
  {id: id++, kind: "LIBLOUIS", language: "greek", grade: "1", pin: 6, text: "Ελληνικά - Grade1", default: true},
  {id: id++, kind: "LIBLOUIS", language: "greek", grade: "2", pin: 6, text: "Ελληνικά - Grade2", default: false},
  {id: id++, kind: "LIBLOUIS", language: "italian", grade: "1", pin: 6, text: "Italiano - Grade1", default: true},
  {id: id++, kind: "DOT", language: "japanese", grade: "1", pin: 6, text: "日本語 - Grade1", default: true},
  {id: id++, kind: "LIBLOUIS", language: "kazakh", grade: "1", pin: 6, text: "қазақ - Grade1", default: true},
  {id: id++, kind: "LIBLOUIS", language: "khmer", grade: "1", pin: 6, text: "ភាសាខ្មែរ - Grade1", default: true},
  {id: id++, kind: "DOT", language: "korean", grade: "1", pin: 6, text: "한국어 - Grade1", default: false},
  {id: id++, kind: "DOT", language: "korean", grade: "2", pin: 6, text: "한국어 - Grade2", default: true},
  {id: id++, kind: "LIBLOUIS", language: "norwegian", grade: "1", pin: 6, text: "Norsk - Grade1", default: true},
  {id: id++, kind: "LIBLOUIS", language: "polish", grade: "1", pin: 6, text: "Polsk - Grade1", default: true},
  {id: id++, kind: "LIBLOUIS", language: "portuguese", grade: "1", pin: 6, text: "Português - Grade1", default: true},
  {id: id++, kind: "DOT", language: "russian", grade: "1", pin: 6, text: "Русский - Grade1", default: true},
  {id: id++, kind: "LIBLOUIS", language: "slovak", grade: "1", pin: 6, text: "Slovenský jazyk - Grade1", default: true},
  {id: id++, kind: "LIBLOUIS", language: "spanish", grade: "1", pin: 6, text: "Español - Grade1", default: true},
  {id: id++, kind: "LIBLOUIS", language: "swedish", grade: "1", pin: 6, text: "Svenska - Grade1", default: true},
  {id: id++, kind: "LIBLOUIS", language: "swedish", grade: "2", pin: 6, text: "Svenska - Grade2", default: false},
  {id: id++, kind: "LIBLOUIS", language: "thai", grade: "1", pin: 6, text: "ภาษาไทย - Grade1", default: true},
  {id: id++, kind: "LIBLOUIS", language: "thai", grade: "2", pin: 6, text: "ภาษาไทย - Grade2", default: false},
  {id: id++, kind: "LIBLOUIS", language: "vietnamese", grade: "1", pin: 6, text: "Tiếng Việt - Grade1", default: false},
  {id: id++, kind: "LIBLOUIS", language: "vietnamese", grade: "2", pin: 6, text: "Tiếng Việt - Grade2", default: true},
]);

// 함수
const setKind = (kind) =>  store.commit("braille/setKind", kind);
const setLanguage = (language) => store.commit("braille/setLanguage", language);
const  setGrade = (grade) =>  store.commit("braille/setGrade", grade);
// eslint-disable-next-line no-unused-vars
const setRule = (rule) =>  store.commit("braille/setRule", rule);
const setPin = (pin) => store.commit("braille/setPin", pin);

const selectLanguage = (languageOption) => {
  if (languageOption.grade === "" && languageOption.kind === "" && languageOption.language === "") {
    return;
  }

  selectedLanguage.value = languageOption;
  changeBrailleLanguage();
};

const changeBrailleLanguage = function () {
  setKind(selectedLanguage.value.kind);
  setLanguage(selectedLanguage.value.language);
  setGrade(selectedLanguage.value.grade);
  setPin(selectedLanguage.value.pin);
  setUserLanguage();
};

/**
 * 사용자 점역 언어 정보 조회
 */
const getUserLanguage = function () {
  const userNo = $session.getUserNo();
  const siteNo = $session.getSiteNo();
  const compNo = $session.getCompNo() || store.getters.COMP_NO;


  if ((isEmpty(userNo) || userNo === "GUEST") || isEmpty(siteNo) || isEmpty(compNo)) {
    return Promise.reject();
  }

  const params = {
    USER_NO: userNo,
    SITE_NO: siteNo,
    COMP_NO: compNo,
    LANGUAGE_TYPE: "1", // "0 = System; 1 = Braille"
  };
  // 값이 없는 속성 제거
  Object.keys(params)
    .map(key => {
      if (!params[key]) {
        delete params[key];
      }
    });
  const query = Object.keys(params).length !== 0 ? `?${new URLSearchParams(params).toString()}` : "";
  const url = `/user-app/v1/sites/${params.SITE_NO}-${params.COMP_NO}/users/${params.USER_NO}/language${query}`;

  return $axios.get(url);
};

const setUserLanguage = function () {
  const userNo = $session.getUserNo();
  const siteNo = $session.getSiteNo();
  const compNo = $session.getCompNo() || store.getters.COMP_NO;

  if ((isEmpty(userNo) || userNo === "GUEST") || isEmpty(siteNo) || isEmpty(compNo)) {
    return Promise.reject();
  }

  if (isEmpty(selectedLanguage.value.language) || isEmpty(selectedLanguage.value.grade) || selectedLanguage.value.pin === 0) {
    return Promise.reject();
  }

  const params = {
    USER_NO: userNo,
    SITE_NO: siteNo,
    COMP_NO: compNo,
    ENGINE: selectedLanguage.value.kind === "DOT" ? "0" : "1", // "0 = Dot; 1 = Liblouis"
    LANGUAGE_TYPE: "1", // "0 = System; 1 = Braille"
    LANGUAGE: selectedLanguage.value.language,
    LANGUAGE_OPTION: selectedLanguage.value.grade,
    PIN: selectedLanguage.value.pin,
  };
  const url = `/user-app/v1/sites/${params.SITE_NO}-${params.COMP_NO}/users/${params.USER_NO}/language`;

  return $axios.put(url, params);
};

const getBrowserLanguage = function ()  {
  const lang = navigator.language || navigator.userLanguage;
  const code = lang.substring(0, 2);
  const languageMap = {
    ar: "arabic",
    zh: "simplified chinese",
    ca: "catalan",
    cs: "czech",
    dk: "danish",
    en: "english",
    fi: "finnish",
    fr: "french",
    de: "german",
    gr: "greek",
    it: "italian",
    ja: "japanese",
    kz: "kazakh",
    km: "khmer",
    ko: "korean",
    nb: "norwegian",
    pl: "polish",
    pt: "portuguese",
    ru: "russian",
    sk: "slovak",
    es: "spanish",
    se: "swedish",
    th: "thai",
    vi: "vietnamese",
  };

  return languageMap[code] || "english";
}

onBeforeMount(() => {
  getUserLanguage()
    .then((response) => {
      if (response.status === 200 && response.data?.RESULT_CODE === "1") {
        const data = response.data;

        if (data.LANGUAGE_TYPE === "1") {
          setKind(data.ENGINE === "0" ? "DOT" : "LIBLOUIS");
          setGrade(data.LANGUAGE_OPTION);
          setLanguage(data.LANGUAGE);
          setPin(data.PIN);
        }
      } else {
        return Promise.reject(response);
      }
    })
    .then(() => {
      const language = store.getters["braille/language"];
      const grade = store.getters["braille/grade"];
      const pin = store.getters["braille/pin"];
      const kind = store.getters["braille/kind"];
      const languageOption = brailleLanguageOptions.value.find(option => option.language === language && option.grade === grade && option.pin === pin && option.kind === kind);
      selectedLanguage.value = languageOption || brailleLanguageOptions.value[0];
    })
    .catch(() => {
      const language = getBrowserLanguage();
      const languageOption = brailleLanguageOptions.value.filter(option => option.language === language);
      const defaultLanguageOption = languageOption.find(option => option.default = true);

      selectedLanguage.value = defaultLanguageOption || languageOption[0] || brailleLanguageOptions.value[0];
    });
});

onMounted(() => selectedLanguage.value = brailleLanguageOptions.value[0]);
</script>

<style scoped>
li {
  list-style: none;
}

li.disabled {
  opacity: 0.2;
}

.dropdown-toggle {
  width: 3.5rem;
  height: 3.5rem;
  line-height: 3.5rem;
  /* background-color: #767474; */
  color: white !important;
  font-size: 2rem;
}

.dropdown-toggle:hover, .dropdown-toggle:active, .dropdown-toggle:focus {
  opacity: .8;
}

.dropdown-toggle::after {
  all: unset;
}

.dropdown-menu {
  border: none;
  margin-top: 10px !important;
  max-height: 375px;
  overflow-x: hidden;
  overflow-y: auto;
}

.dropdown-item:focus, .dropdown-item:hover {
  background: #ea5414 !important;
}

.dropdown-item {
  color: #f7f7f7;
  font-size: 20px;
}
</style>
